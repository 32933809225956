/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.PageError {
  padding: 8rem;
  background: #f9f6ef;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}
.PageError .title {
  font-size: 3em;
  font-weight: 600;
  color: #745a9e;
  font-family: 'Zona Pro', 'Verdana';
  text-align: center;
  padding-bottom: 3rem;
}
@media (max-width: 991px) {
  .PageError .title {
    font-size: 2.2em;
    margin-left: 1rem;
  }
}
